import React, { useCallback, useEffect, useState } from "react";
import getImages from "../../../../services/getImages";
import { Container, Image } from "./styles";

interface PropTypes {
  onImageClick: (image: string) => void;
}

const ImagesList: React.FC<PropTypes> = ({ onImageClick }) => {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    const getUrls = async () => {
      const urls = await getImages();

      setImages(urls);
    };

    getUrls();
  }, []);

  const renderImages = useCallback(
    () =>
      images.map((url, index) => (
        <Image
          onClick={() => onImageClick(url)}
          key={index}
          src={url}
          alt="portifolio"
          loading="lazy"
        />
      )),
    [images, onImageClick]
  );

  return <Container>{renderImages()}</Container>;
};

export default ImagesList;

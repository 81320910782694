import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  align-items: center;
  backface-visibility: hidden;
  background-color: transparent;
  padding: 0.5rem;
  transition: all 0.2s;

  @media (min-width: 1200px) {
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }

  @media (max-width: 1200px) {
    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  &:hover {
    border-radius: 10px;
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  }
`;

export const Icon = styled.img`
  width: 3rem;
  height: 3rem;
`;

export const Label = styled.label`
  font-size: ${(props) => props.theme.fonts.sizes.medium};
  color: ${(props) => props.theme.colors.font.main};

  margin-left: 0.5rem;

  cursor: pointer;
`;

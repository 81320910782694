import React, { useEffect, useRef } from "react";
import { Container, Image } from "./styles";

interface PropTypes {
  open: boolean;
  onClose: () => void;
  image: string;
}

const ImageModal: React.FC<PropTypes> = ({ open, onClose, image }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    //@ts-ignore
    const handleClickOutside = (event) => {
      //@ts-ignore
      if (contentRef.current && !contentRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [contentRef, onClose]);

  return (
    <Container open={open}>
      <Image
        ref={contentRef}
        src={image}
        alt="big portifolio"
        key={image}
        loading="lazy"
      />
    </Container>
  );
};

export default ImageModal;

import React from "react";

import { Container, LabelCopyRight, ListItem } from "./styles";

const Footer: React.FC = () => {

  //@ts-ignore
  const onMarcosClick = () => window.location="https://www.linkedin.com/in/marcos-marques-681167146/";

  return (
    <Container>
      <LabelCopyRight>
        &copy;{` 2022 - ${new Date().getFullYear() + 1}`}
      </LabelCopyRight>
      <LabelCopyRight>
        Build by - <ListItem onClick={onMarcosClick}>Marcos Marques</ListItem>
      </LabelCopyRight>
    </Container>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import emailjs, { init } from "emailjs-com";

import {
  Container,
  Content,
  FormContainer,
  Form,
  Space,
  SocialContainer,
} from "./styles";

// Components
import { Row } from "../../../../components/grid";
import TextField from "../../../../components/textField";
import HeaderLabel from "../../../../components/headerLabel";
import MyButton from "../../../../components/myButton";
import SocialButton from "../../../../components/socialButton";

// Assets
import wattsIcon from "../../../../assets/wattsIcon.png";
import faceIcon from "../../../../assets/faceIcon.png";
import instaIcon from "../../../../assets/instaIcon.png";

const Index: React.FC = () => {
  const theme = useTheme();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    init("H_IqR4DUtUcuxXocL");
  }, []);

  const onWattsClick = () =>
    //@ts-ignore
    (window.location = "https://api.whatsapp.com/send?phone=5571987020688");

  const onFaceClick = () =>
    //@ts-ignore
    (window.location =
      "https://www.facebook.com/Nova-design-country-102256848992094");

  const onInstaClick = () =>
    //@ts-ignore
    (window.location = "https://www.instagram.com/novadesigncountry/");

  //@ts-ignore
  const onSendEmail = async (event) => {
    try {
      event.preventDefault();
      await emailjs.send("service_isjlypf", "template_y96laml", {
        name,
        email,
        message,
      });

      alert("Mensagem enviada com sucesso");
    } catch {
      alert("Mensagem não pode ser enviada, tente novamente mais tarder");
    }
  };

  return (
    <Container>
      <Row>
        <Content>
          <FormContainer>
            <HeaderLabel>Contate-nos</HeaderLabel>
            <Form onSubmit={onSendEmail}>
              <TextField
                type="text"
                placeholder="Nome Completo"
                label="Nome Completo"
                value={name}
                onChange={setName}
                id="name"
                required
              />
              <TextField
                type="email"
                placeholder="Email"
                label="Email"
                value={email}
                onChange={setEmail}
                id="email"
                required
              />
              <TextField
                type="textarea"
                placeholder="Menssagem"
                label="Menssagem"
                value={message}
                onChange={setMessage}
                id="message"
                required
              />
              <Space />
              <MyButton
                type="submit"
                label="Enviar &rarr;"
                backgroundColor={theme.colors.main.lightGreen}
                textColor={theme.colors.font.white}
              />
            </Form>
            <SocialContainer>
              <SocialButton
                onClick={onFaceClick}
                icon={faceIcon}
                label="Facebook"
              />
              <SocialButton
                onClick={onInstaClick}
                icon={instaIcon}
                label="Instagram"
              />
              <SocialButton
                onClick={onWattsClick}
                icon={wattsIcon}
                label="Whatsapp"
              />
            </SocialContainer>
          </FormContainer>
        </Content>
      </Row>
    </Container>
  );
};

export default Index;

import React from "react";
import { useTheme } from "styled-components";

import {
  Container,
  LogoBox,
  Logo,
  LblHeader,
  MainText,
  SubText,
} from "./styles";

// Assets
import logo from "../../../../assets/logo.png";

// Components
import Button from "../../../../components/myButton";
import { useNavigate } from "react-router-dom";

const Header: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const onPortifolioClick = () => navigate("/");

  return (
    <Container>
      <LogoBox>
        <Logo src={logo} alt="logo" />
      </LogoBox>
      <LblHeader>
        <MainText>Design Country</MainText>
        <SubText>móveis rústicos</SubText>
      </LblHeader>
      <Button
        onClick={onPortifolioClick}
        label="voltar ao início"
        backgroundColor={theme.colors.font.white}
        textColor={theme.colors.main.darkGreen}
        animated
      />
    </Container>
  );
};

export default Header;

import React from "react";
import styled from "styled-components";

// Sections
import Header from "./sections/header";
import About from "./sections/about";
import Booking from "./sections/booking";

// Components
import Footer from "../../components/footer";

const Content = styled.main`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

const Home: React.FC = () => {

  return (
    <section>
      <Header />
      <Content>
        <About />
        <Booking />
      </Content>
      <Footer />
    </section>
  );
};

export default Home;

import React from "react";
import { useTheme } from "styled-components";

import {
  Container,
  LogoBox,
  Logo,
  LblHeader,
  MainText,
  SubText,
  CenterContent,
} from "./styles";

// Assets
import logo from "../../../../assets/logo.png";

// Components
import Button from "../../../../components/myButton";
import { useNavigate } from "react-router-dom";

const Index: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const onPortifolioClick = () => navigate("portifolio");

  return (
    <Container>
      <LogoBox>
        <Logo src={logo} alt="logo" />
      </LogoBox>
      <CenterContent>
        <LblHeader>
          <MainText>Design Country</MainText>
          <SubText>móveis rústicos</SubText>
        </LblHeader>
        <Button
          onClick={onPortifolioClick}
          label="conheça nossos produtos"
          backgroundColor={theme.colors.font.white}
          textColor={theme.colors.main.darkGreen}
          animated
        />
      </CenterContent>
    </Container>
  );
};

export default Index;

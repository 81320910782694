import React, { useState } from "react";
import styled from "styled-components";

// Components
import Footer from "../../components/footer";

// Sections
import ImagesList from "./sections/portifolio";
import Header from "./sections/header";
import ImageModal from "../../components/imageModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.main.darkGreen};
`;

const Portifolio: React.FC = () => {
  const [image, setImage] = useState<string | null>(null);

  return (
    <Container>
      <Header />
      <ImagesList onImageClick={setImage} />
      <Footer />
      <ImageModal
        open={image !== null}
        image={image || ""}
        onClose={() => setImage(null)}
      />
    </Container>
  );
};

export default Portifolio;

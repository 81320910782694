import React from "react";
import { ThemeProvider } from "styled-components";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from "react-router-dom";

// Utils
import GlobalCss from "./utils/globalCss";
import defaultTheme from "./themes/defaultTheme";

//Pages
import Home from "./pages/home";
import Portifolio from "./pages/portifolio";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalCss />
      <Router>
        <Switch>
          <Route path="/" element={<Home />} />
          <Route path="portifolio" element={<Portifolio />} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;

import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { getApp } from "firebase/app";

const getImages = async () => {
  try {
    const images: string[] = [];
    const storage = getStorage(getApp());

    const listRef = ref(storage, "portifolio/");

    const { items } = await listAll(listRef);

    const promises = items.map(async (item) =>
      images.push(await getDownloadURL(ref(storage, `portifolio/${item.name}`)))
    );

    await Promise.all(promises);

    return images;
  } catch (err) {
    alert("Não foi possível recuperar dados do servidor");

    return [];
  }
};

export default getImages;

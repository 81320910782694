const defaultTheme = {
  title: "default",
  colors: {
    main: {
      lightGreen: "#a34230",
      mediumGreen: "#502018",
      darkGreen: "#502018",
      lightOrange: "#ffb900",
      darkOrange: "#ff7730",
      lightBlue: "#2998ff",
      darkBlue: "#5643fa",
    },
    font: {
      placeholder: "#999999",
      light: "#eeeeee",
      main: "#777777",
      white: "#ffffff",
    },
    background: {
      about: "#f7f7f7",
      footer: "#333333",
    },
  },
  fonts: {
    sizes: {
      small: "1.2rem",
      medium: "1.8rem",
      larger: "2.1rem",
    },
  },
};

export default defaultTheme;

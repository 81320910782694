import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const Image = styled.img`
  width: 26rem;
  height: 16rem;
  margin-top: 3rem;
  border-radius: 3%;
  background-color: ${({ theme }) => theme.colors.font.white};
  cursor: pointer;
  backface-visibility: hidden;
  transition: all 0.2s;
  object-fit: cover;

  &:last-child {
    margin-bottom: 5rem;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.main.mediumGreen};
    color: ${(props) => props.theme.colors.font.white};
    transform: translateY(-1rem);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
    width: 27rem;
    height: 17rem;
  }
`;

import React from "react";

import {
  Container,
  HeaderContainer,
  ImagesContainer,
  Paragraph,
  Subheader,
} from "./styles";
import { Row } from "../../../../components/grid";
import HeaderLabel from "../../../../components/headerLabel";

// Images
import p1 from "../../../../assets/nat-1-large.jpg";
import p2 from "../../../../assets/nat-2-large.jpg";
import p3 from "../../../../assets/nat-3-large.jpg";

const Index: React.FC = () => {
  return (
    <Container>
      <HeaderContainer>
        <HeaderLabel>Quem somos</HeaderLabel>
      </HeaderContainer>
      <div style={{ height: "8rem" }} />
      <Row>
        <div className="col _1of2">
          <Subheader>Um pouco da nossa história </Subheader>
          <Paragraph>
            A Design Country Móveis Rústicos está no mercado desde 1999 e possui
            uma instalação completa, totalmente capaz de suprir às suas
            exigências, oferecendo serviços customizados, atendendo aos seus
            projetos ou executando projetos elaborados no próprio atelier
          </Paragraph>
          <Subheader>Resistência, durabilidade e muito estilo!</Subheader>
          <Paragraph>
            Móveis e estruturas em madeira maciça confeccionados sob o conceito
            de “Handmade” (feitas à mão). Peças exclusivas com o estilo de
            decoração desejado, do clássico ao casual, design country a sua
            escolha ideal. Somos especialista em montagem de restaurantes e
            móveis rústicos.
          </Paragraph>
        </div>
        <div className="col _1of2">
          <ImagesContainer>
            <img
              src={p3}
              key="nature 1"
              loading="lazy"
              alt="nature 1"
              className="p1"
            />
            <img
              src={p2}
              key="nature 2"
              loading="lazy"
              alt="nature 2"
              className="p2"
            />
            <img
              src={p1}
              key="nature 3"
              loading="lazy"
              alt="nature 3"
              className="p3"
            />
          </ImagesContainer>
        </div>
      </Row>
    </Container>
  );
};

export default Index;

const firebaseConfig = {
  apiKey: "AIzaSyAje2eDu0fMeTxPnjMpb2lUXXHqanm4RSM",
  authDomain: "design-country.firebaseapp.com",
  projectId: "design-country",
  storageBucket: "design-country.appspot.com",
  messagingSenderId: "193441492232",
  appId: "1:193441492232:web:c3547be6b688a04a7290bd",
  measurementId: "G-KTB9NRWNHN",
};

export default firebaseConfig;

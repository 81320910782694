import React from "react";
import { Container, Icon, Label } from "./styles";

interface PropTypes {
  icon: string;
  label: string;
  onClick: () => void;
}

const SocialButton: React.FC<PropTypes> = ({ label, icon, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Icon alt="social media" src={icon} />
      <Label>{label}</Label>
    </Container>
  );
};

export default SocialButton;

import React from "react";

import { Container, SbmtContainer } from "./styles";

interface props {
  label: string;
  onClick?: () => void;
  backgroundColor?: string;
  textColor?: string;
  href?: string;
  animated?: boolean;
  type?: string;
}

const Index: React.FC<props> = ({
  label,
  onClick,
  backgroundColor,
  textColor,
  href,
  animated,
  type,
}) => {
  if (type === "submit")
    return (
      <SbmtContainer
        type="submit"
        backgroundColor={backgroundColor}
        textColor={textColor}
        animated={animated}
      >
        {label}
      </SbmtContainer>
    );
  return (
    <Container
      href={href}
      backgroundColor={backgroundColor}
      textColor={textColor}
      animated={animated}
      onClick={onClick}
    >
      {label}
    </Container>
  );
};

export default Index;

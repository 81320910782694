import styled from "styled-components";

interface ContainerProps {
  open?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: ${({ open }) => (open ? "flex" : "none")};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  max-width: 80%;
  max-height: 70vh;
  backface-visibility: hidden;
  object-fit: contain;
  border-radius: 10px;
`;
